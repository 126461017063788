import { t } from '@lib/i18n';
import Typography from '@ui/Typography';
import { RequestFeedback } from '@features/request-feedback';

const tr = (key) => t(`gdpr.book_demo.${key}`);

export const BookDemo = () => {
  return (
    <section className="mb-9 mt-8 text-center sm:mb-7 sm:px-3 md:mb-8 md:px-12 lg:mt-9">
      <Typography variant="header2">{tr('title')}</Typography>
      <Typography variant="subheader1" className="mt-3">{tr('subtitle')}</Typography>
      <RequestFeedback.Demo.Button className="mt-4">
        {t('main_page.request_demo')}
      </RequestFeedback.Demo.Button>
    </section>
  );
};
