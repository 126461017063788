import { t } from '@lib/i18n';
import { bem } from '@lib/bem';
import MediaQuery from '@ui/MediaQuery';
import Typography from '@ui/Typography';
import Slider from '@ui/Slider';

import '../../main-page/ui/ExtraFeatures/ExtraFeatures.scss';

const tr = (key) => t(`gdpr.what_we_are.${key}`);

const cards = tr('cards');

const {
  block,
  element,
} = bem('extra-features');

const FeatureCard = ({ item }) => {
  return (
    <div className="h-full sm:mx-1 sm:h-[344px] md:h-[320px]">
      <div {...element('item-content', {}, 'h-full rounded-[16px] lg:shadow-[6px_6px_40px_0px_#ECF2FF] mx-auto')}>
        <Typography variant="header6" weight="medium">
          {item.title}
        </Typography>
        <Typography className="mt-2 [&>a]:text-primary400">
          {item.body}
        </Typography>
      </div>
    </div>
  );
};

export const WhatWeAreDoing = () => {
  return (
    <section className="lg:grid-layout mt-7 mb-12 sm:mx-[-16px] sm:mb-7 sm:px-3 md:my-8 lg:mx-auto lg:my-9">
      <Typography variant="header2" center>{tr('title')}</Typography>
      <Typography variant="subhead1" center className="mt-3">{tr('subtitle')}</Typography>
      <MediaQuery at="sm">
        <div className="mt-7">
          <Slider gutter={4}>
            {
              cards.map((card, i) => (
                <FeatureCard item={card} key={i} />
              ))
            }
          </Slider>
        </div>
      </MediaQuery>
      <MediaQuery at="md">
        <Slider
          gutter={4}
          settings={{
            slidesToShow: 1.9,
            slidesToScroll: 1,
          }}
          className="ml-3 mt-8"
        >
          {
            cards.map((card, i) => (
              <FeatureCard item={card} key={i} className="mx-auto" />
            ))
          }
        </Slider>
      </MediaQuery>
      <MediaQuery greaterThan="md">
        <div className="mx-auto mt-9 grid max-w-[1312px] grid-cols-3 gap-4">
          {
            cards.map((card, i) => (
              <FeatureCard item={card} key={i} />
            ))
          }
        </div>
      </MediaQuery>
    </section>
  );
};
