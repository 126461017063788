import { t } from '@lib/i18n';
import { FeatureLayout, Layout } from '@widgets/layout';
import SupportBlock from '@ui/Price/SupportBlock';
import Typography from '@ui/Typography';
import { block } from 'bem-cn';
import clsx from 'clsx';
import { WhatWeAreDoing } from '../ui/WhatWeAreDoing';
import { BookDemo } from '../ui/BookDemo';

const cn = block('price-page');

const tr = (key) => t(`gdpr.${key}`);

const faq = tr('faq');

const FeatureContent = () => (
  <>
    <Typography variant="header1" center>
      {tr('header')}
    </Typography>
    <Typography variant="subhead1" center className="!mt-4">
      {tr('subheader')}
    </Typography>
  </>
);

export const GDPR = () => {
  return (
    <FeatureLayout
      mainLayoutProps={{ seo: t('seo.gdpr') }}
      featureChildren={<FeatureContent />}
    >
      <WhatWeAreDoing />
      <SupportBlock items={faq} />
      <BookDemo />
    </FeatureLayout>
  );
};
